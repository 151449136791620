/*====================================================
    dashboard-nav
 ====================================================*/
.header-desktop {
  .header-menu-wrapper {
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: 1px solid rgba(128, 137, 150, 0.2);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    @media #{$laptop_m_four} {
      height: 80px;
    }
    @media #{$tab_device} {
      padding-left: 0;
      padding-right: 0;
    }
    @media #{$large_mobile} {
      padding-left: 0;
      padding-right: 0;
    }
    @media #{$small_mobile} {
      padding-left: 0;
      padding-right: 0;
    }
    .logo {
      width: 300px;
      border-right: 1px solid rgba(128, 137, 150, 0.2);
      @media #{$laptop_m_four} {
       border-right: 0;
        width: auto;
      }
    }
  }
  .main-menu-content {
    margin-left: 0;
    padding-right: 0;
    text-align: left;
    padding-left: 40px;
  }
  .logo-right-content {
    margin-left: auto;
    .dropdown-menu {
      left: auto !important;
      @include transform(translate3d(0, 0, 0) !important);
      @include box-shadow(0 2px 40px rgba(82, 85, 90, 0.1));
      right: -30px;
      margin-top: 65px;
      width: 310px;
      padding: 0;
      border: 1px solid rgba(128, 137, 150, 0.1);
      @media #{$tab_device} {
        margin-top: 60px;
        right: -70px;
      }
      @media #{$large_mobile} {
        margin-top: 60px;
        right: -70px;
      }
      @media #{$small_mobile_four} {
        width: 300px;
      }
      @media #{$small_mobile} {
        margin-top: 60px;
        right: -70px;
      }
    }
    .dropdown-menu.show {
      @include transform(translate3d(0, 0, 0) !important);
    }
    .header-action-button {
      @media #{$laptop_m_four} {
       margin-right: 20px;
      }
      @media #{$small_mobile_four} {
        display: none !important;
      }
      .notification-wrap {
        .dropdown-menu {
          @media #{$small_mobile_four} {
           right: -100px;
          }
        }
      }
      .user-action-wrap {
        .dropdown-menu {
          width: 280px;
        }
      }
      &.active {
        display: block;
      }
    }
    .side-menu-open {
      @media #{$small_mobile_four} {
        margin-right: 8px;
      }
    }
  }
}

.dot-status {
  position: relative;
  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    height: 14px;
    width: 14px;
    background-color: $color;
    display: block;
    border: 2px solid $color-light;
    box-shadow: 0 1px 3px rgba(0,0,0,.2);
    @include border-radius(50%);
  }
}
.notification-btn {
  position: relative;
  border: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  @include border-radius(50%);
  background-color: rgba(38, 174, 97, 0.1);
  color: $theme-color-2;
  font-size: $display-22;
  outline: none !important;
  @include transition(0.3s);
  &:after {
    display: none;
  }
  &:hover {
    background-color: $theme-color-2;
    color: $color-light;
  }
}
.notification-btn[aria-expanded="true"] {
  background-color: $theme-color-2;
  color: $color-light;
}
.notification-item {
  .quantity {
    position: absolute;
    display: inline-block;
    top: -7px;
    right: -6px;
    height: 22px;
    width: 22px;
    line-height: 19px;
    text-align: center;
    background-color: $theme-color-2;
    color: $color-light;
    @include border-radius(50%);
    font-size: $default-font-6;
    border: 2px solid $color-light;
    font-weight: $font-weight-bold;
  }
}
.mess-dropdown {
  position: relative;
}
.mess__title {
  padding: 20px;
  border-bottom: 1px solid rgba(128, 137, 150, 0.1);
  .widget-title {
    font-size: $default-font;
    padding-bottom: 0;
  }
  p {
    font-weight: $font-weight-regular;
    color: $theme-color-4;
    font-size: $default-font-3;
  }
}
.mess__body {
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  font-weight: $font-weight-medium;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(128, 137, 150, 0.1);
    @include border-radius(10px);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 137, 150, 0.2);
    @include border-radius(10px);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(128, 137, 150, 0.4);
  }
}
.mess__item {
  padding: 19px 20px;
  border-bottom: 1px solid rgba(128, 137, 150, 0.2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  @include transition(0.3s);
  .avatar {
    margin-right: 15px;
    width: 60px;
    height: 60px;
    @include border-radius(50%);
    position: relative;
    img {
      width: 60px;
      height: 60px;
      @include border-radius(50%);
    }
  }
  .icon-element {
    background-color: rgba(38, 174, 97, 0.1);
    color: $theme-color-2;
    margin-right: 15px;
    flex-shrink: 0;
  }
  .content {
    width: -webkit-calc(100% - 55px);
    width: -moz-calc(100% - 55px);
    width: calc(100% - 55px);
    text-align: left;
    .widget-title {
      font-size: $default-font-3;
      padding-top: 4px;
      margin-bottom: 3px;
      padding-bottom: 0;
    }
    .text {
      font-size: $default-font-3;
      color: $theme-color-4;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      font-size: $default-font-4;
      color: $theme-color-2;
    }
  }
  &:hover {
    background-color: rgba(128, 137, 150, 0.05);
  }
}
.online-status {
  &:before {
    background-color: $theme-color-2 !important;
  }
}

.user-action-wrap {
  .notification-btn {
    position: relative;
    background-color: transparent;
    &:hover {
      background-color: transparent;
    }
    img {
      @include border-radius(50%);
      width: 100%;
      border: 1px solid rgba(128, 137, 150, 0.2);
    }
  }
  .notification-btn[aria-expanded="true"] {
    background-color: transparent;
    color: transparent;
  }
  .image {
    width: 50px;
    @include border-radius(50%);
    height: 50px;
    margin-right: 15px;
    img {
      width: 100%;
      @include border-radius(50%);
      border: 1px solid rgba(128, 137, 150, 0.2);
    }
  }
  .widget-title {
    font-weight: $font-weight-bold;
    padding-bottom: 0;
    font-size: $display-24;
    a {
      color: $theme-color;
    }
  }
  .email {
    color: $theme-color-4;
    font-size: $default-font-4;
  }
  .mess__body {
    max-height: 100%;
    overflow-x: inherit;
    overflow-y: inherit;
    padding: 15px 20px;
    a {
     padding-top: 5px;
      padding-bottom: 5px;
      color: $theme-color;
      @include transition(0.3s);
      i {
        margin-right: 5px;
      }
      &:hover {
        color: $theme-color-2;
      }
    }
  }
}

.dashboard-area {
  height: 100vh;
  @media #{$laptop_m_five} {
    padding-top: 120px;
  }
}
.dashboard-nav-trigger {
  padding: 0 15px 35px 15px;
  display: none;
  @media #{$laptop_m_five} {
   display: block;
  }
}
.dashboard-nav-trigger-btn {
  background-color: $theme-color;
  padding: 10px 15px 10px 45px;
  @include border-radius(4px);
  cursor: pointer;
  color: $color-light;
  font-size: $display-24;
  display: inline-block;
  position: relative;
  .la {
    font-size: $display-20;
    position: absolute;
    top: 50%;
    left: 15px;
    @include transform(translateY(-50%));
  }
}
.dashboard-content-wrap {
  height: 100vh;
  padding-top: 130px;
  padding-left: 368px;
  padding-right: 30px;
  @media #{$laptop_m_five} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .breadcrumb-content {
    @media #{$large_mobile} {
      text-align: left !important;
    }
    @media #{$small_mobile} {
      text-align: left !important;
    }
    .section-heading {
      .sec__title {
        color: $theme-color;
        font-size: $display-16;
      }
    }
    .list-items {
      background-color: rgba(44, 44, 81, 0.08);
      li {
        color: $theme-color;
        a {
          color: $theme-color;
          &:hover {
            color: $theme-color-2;
          }
        }
      }
    }
  }
}

.chart-item {
  .select2-container {
    .select2-selection--single {
      background-color: rgba(38, 174, 97, 0.1);
      padding-top: 5px;
      padding-bottom: 5px;
      border: none;
      .select2-selection__arrow {
        b {
          border-color: $theme-color-2 transparent transparent transparent;
        }
      }
    }
    &.select2-container--default {
      &.select2-container--open{
        .select2-selection--single {
          .select2-selection__arrow {
            b {
              border-color: transparent transparent $theme-color-2 transparent;
            }
          }
        }
      }
      .select2-selection--single {
        .select2-selection__placeholder {
          color: $theme-color-2;
        }
      }
    }
  }
  .short-option {
    width: auto;
    .nice-select {
      background-color: rgba(38, 174, 97, 0.1);
      border: none;
      color: $theme-color-2;
      font-weight: $font-weight-medium;
      font-size: $default-font-4;
      .option {
        color: $theme-color;
        &.selected.focus {
          background-color: rgba(38, 174, 97, 0.07);
          color: $theme-color-2;
          font-weight: $font-weight-semi-bold;
        }
      }
      &:after {
        border-right-color: $theme-color-2;
        border-bottom-color: $theme-color-2;
      }
      .list {
        @include box-shadow(0 0 18px 0 rgba(0,0,0,0.1));
      }
    }
  }
}


.overview-item {
  margin-bottom: 30px;
  .icon-box {
    @media #{$laptop_l} {
      display: block !important;
      text-align: center;
    }
    &:after {
      display: none;
    }
    .icon-element {
      margin-left: 0;
      margin-right: 20px;
      background-color: rgba(255, 255, 255, 0.1);
      @media #{$laptop_l} {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
    }
    .info__count,
    .info__title {
      color: $color-light;
    }
    .info__title {
      @media #{$laptop_l} {
        font-size: $display-24;
      }
    }
    &:hover {
      .icon-element {
        color: $theme-color;
        background-color: $color-light;
      }
    }
  }
}

.dashboard-shared {
  @include border-radius(4px);
  @include box-shadow(0 0 18px 0 rgba(44,44,81,0.1));
  .mess-dropdown {
    .mess__title {
      padding: 0;
      position: relative;
      @media #{$laptop_l} {
        height: 85px;
      }
      .img-fluid {
        @include border-radius(4px 4px 0 0);
        @media #{$laptop_l} {
          height: 100%;
        }
      }
      .widget-title {
        font-size: $display-23;
        color: $color-light;
      }
      .mess__title-inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
      &:after {
        background: rgba(49, 89, 253, 0.9);
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include border-radius(4px 4px 0 0);
      }
    }
    .mess__body {
      max-height: 374px;
    }
    &:after {
      display: none;
    }
  }
}
.timeline-dashboard {
  .mess__body {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 30px;
      width: 3px;
      top: 5px;
      bottom: 5px;
      height: calc(100% - 5px);
      background-color: rgba(128, 137, 150, 0.1);
    }
    .mess__item {
      border-bottom: none;
      padding-left: 50px;
      position: relative;
    }
  }
}

.note-dashboard {
  .mess-dropdown {
    .mess__body {
      max-height: 310px;
      .mess__item {
        display: block;
      }
    }
  }
}
.edit-profile-wrap {
  @media #{$tab_device} {
   display: block !important;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
  }
  @media #{$large_mobile} {
   display: block !important;
  }
  @media #{$small_mobile} {
   display: block !important;
  }
}

.user-profile-action {
  .user-pro-img {
    position: relative;
    width: 200px;
    margin-right: 30px;
    img {
      width: 100%;
    }
  }
}

.user-edit-form {
  .edit-form-btn {
    color: $theme-color;
    background-color: rgba(128, 137, 150, 0.2);
    border: 1px solid rgba(128, 137, 150, 0.3);
    line-height: 44px;
    position: relative;
    outline: none;
    &:hover {
      background-color: rgba(128, 137, 150, 0.4);;
      border-color: rgba(128, 137, 150, 0.6);;
    }
  }
  .dropdown-menu {
    min-width: 100%;
    margin-top: 15px;
    border: 1px solid rgba(128, 137, 150, 0.2);
    padding: 20px;
  }
}

.delete-info-content {
  .theme-btn {
    outline: none;
  }
  p {
    font-weight: $font-weight-medium;
    span {
      font-weight: $font-weight-semi-bold;
    }
  }
}

.manage-candidate-wrap {
  @media #{$large_mobile} {
    display: block !important;
  }
  @media #{$small_mobile} {
    display: block !important;
  }
  .bread-details {
    @media #{$large_mobile_three} {
      display: block !important;
    }
    .bread-img {
      @media #{$large_mobile_three} {
       margin-right: 0;
        margin-bottom: 20px;
      }
    }
  }
  .bread-action {
    @media #{$large_mobile} {
      padding-top: 10px;
      padding-left: 120px;
    }
    @media #{$large_mobile_three} {
      padding-top: 20px;
      padding-left: 0;
    }
    .info-list {
      li {
        a {
          display: block;
        }
        .la {
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: $display-22;
        }
      }
    }
  }
}

.manage-job-wrap {
  .bread-action {
    .icon-element {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: $display-24;
    }
  }
  .table {
    color: $theme-color;
    margin-bottom: 0;
    @media #{$tab_device_two} {
      width: 800px;
    }
    @media #{$device-767} {
      width: 830px;
    }
    thead {
      th {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        border-bottom: none;
        border-top: none;
      }
    }
    tbody {
      tr {
        td {
          color: $theme-color-4;
          padding-left: 0;
          padding-top: 20px;
          padding-bottom: 25px;
          border-top-color: rgba(128, 137, 150, 0.2);
        }
      }
    }
  }
}
.my-resume-wrap {
  .table {
    tbody {
      border-bottom: 1px solid rgba(128, 137, 150, 0.2);
      tr {
        td {
          padding-bottom: 13px;
        }
      }
    }
  }
}
.add-item-form {
  position: relative;
  display: none;
  .add-more-close {
    .icon-element {
      position: absolute;
      top: -15px;
      right: 20px;
      width: 35px;
      height: 35px;
      line-height: 33px;
      font-size: $default-font;
      cursor: pointer;
      background-color: $color-light;
      color: $theme-color-4;
      border: 1px solid rgba(128, 137, 150, 0.2);
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
  &.active {
    display: block;
  }
}
.dashboard-message-wrapper {
  border: 1px solid rgba(128, 137, 150, 0.2);
  @include border-radius(4px);
  background-color: $color-light;
  @include box-shadow(0 0 18px 0 rgba(0,0,0,.01));
  @media #{$large_mobile} {
    display: block !important;
  }
  @media #{$small_mobile} {
    display: block !important;
  }
}

.message-sidebar {
  width: 320px;
  border-right: 1px solid rgba(128, 137, 150, 0.2);
  @media #{$laptop_m_four} {
    width: 250px;
  }
  @media #{$large_mobile} {
    width: auto;
  }
  @media #{$small_mobile} {
    width: auto;
  }
  .contact-form-action {
    .form-control {
      padding-left: 20px;
      background-color: rgba(128, 137, 150, 0.08);
      @include border-radius(30px);
      border: none;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
.message-search {
  padding: 27px 30px;
  border-bottom: 1px solid rgba(128, 137, 150, 0.2);
}

.message-inbox-item {
  .mess__body {
    max-height: 435px;
  }
  .mess__item {
    padding: 20px 30px 0 30px;
    border-bottom: none;
    .avatar {
      width: 50px;
      height: 50px;
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .content {
    .widget-title {
      padding-top: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
}
.message-inbox {
  position: relative;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: $color-light;
    @include border-radius(0 4px 4px 0);
    @include transition(0.3s);
  }
  &:hover {
    background-color: rgba(38, 174, 97, 0.05);
    &:after {
      background-color: $theme-color-2;
    }
  }
}
.message-active {
  background-color: rgba(38, 174, 97, 0.05);
  &:after {
    background-color: $theme-color-2;
  }
}

.message-content {
  .mess__item {
    padding-right: 30px;
    padding-left: 30px;
    &:hover {
      background-color: transparent;
    }
  }
}

.msg-action-bar {
  padding: 14px 30px;
  font-size: $default-font-4;
  border-top: 1px solid rgba(128, 137, 150, 0.2);
  a {
    color: $theme-color;
    @include transition(0.3s);
    background-color: rgba(44, 44, 81, 0.05);
    padding: 2px 8px;
    @include border-radius(30px);
    &:hover {
      color: $theme-color-2;
    }
  }
}
.conversation-wrap {
  padding: 20px 0px;
}
.conversation-box {
  max-height: 620px;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(128, 137, 150, 0.1);
    @include border-radius(10px);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(128, 137, 150, 0.2);
    @include border-radius(10px);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(128, 137, 150, 0.4);
  }
}
.message-time {
  text-align: center;
  display: block;
  margin-bottom: 24px;
  span {
    background-color: rgba(38, 174, 97, 0.07);
    padding: 2px 8px;
    @include border-radius(4px);
    color: $theme-color-2;
    font-size: $default-font-2;
  }
}

.conversation-item {
  .mess__body {
    max-height: inherit;
    overflow-x: inherit;
    overflow-y: inherit;
  }
  .mess__item {
    border-bottom: none;
    padding-top: 0;
    .content {
      display: inline-block;
      padding: 10px 15px;
      @include border-radius(4px 0 4px 4px);
      max-width: 250px;
      position: relative;
      .text{
        margin-bottom: 1px;
        color: $color-light;
        white-space: inherit;
        overflow: inherit;
        text-overflow: inherit;
        line-height: 20px;
      }
      .time {
        color: rgba(255, 255, 255, 0.7);
        font-size: $default-font-5;
      }
      &:after {
        content: '';
        position: absolute;
        right: -12px;
        top: 0;
        width: 0;
        height: 0;
        border: 12px solid transparent;
        border-left-color: $theme-color-2;
        border-right: 0;
        border-top: 0;
      }
      &.msg-typing {
        max-width: 106px;
        max-height: 50px;
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        .text {
          margin-right: 3px;
          margin-bottom: 0;
        }
        .typing-director {
          padding-top: 8px;
        }
      }
    }
  }
}

.msg-sent {
  .mess__item {
    -ms-flex-pack: end;
    justify-content: flex-end;
    .content {
      background-color: $theme-color-2;
      .time {
       float: right;
        .fa-check {
          color: $color-light;
        }
      }
    }
    .avatar {
      margin-right: 0;
      margin-left: 13px;
    }
  }
}

.msg-reply {
  .mess__item {
    .content {
      background-color: rgba(128, 137, 150, 0.1);
      .text {
        color: $theme-color-6;
      }
      .time {
        float: left;
        color: rgba(128, 137, 150, 0.7);
      }
      &:after {
        right: auto;
        left: -12px;
        border-right: 12px solid rgba(128, 137, 150, 0.1);
        border-left: 0;
        border-top: 0;
      }
    }
    .avatar {
      margin-right: 13px;
    }
  }
}

.message-reply-input {
  padding: 20px 30px;
  background-color: $color-light;
  @include box-shadow(0 0 18px 0 rgba(0,0,0,0.05));
  border-top: 1px solid rgba(128, 137, 150, 0.2);
  @media #{$small_mobile_five} {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media #{$small_mobile_two} {
    padding-right: 10px;
    padding-left: 10px;
  }
  .contact-form-action {
    .form-group {
      @media #{$small_mobile_five} {
        display: block !important;
      }
    }
    .dropdown-menu {
      min-width: 190px;
      padding: 10px 20px;
      top: -127px !important;
      @include transform(translate3d(0, 0, 0) !important);
      left: -130px !important;
      text-align: center;
      background-color: $color-light;
      @include box-shadow(0 0 18px 0 rgba(0,0,0,0.05));
      border-color: rgba(128, 137, 150, 0.1);
      @media #{$small_mobile_five} {
        left: -135px !important;
      }
      .dropdown-item {
        width: auto;
        display: inline-block;
        padding: 0;
        margin: 3px;
        &:hover {
          background-color: transparent;
        }
      }
      .icon-element {
        margin-left: 0;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: $display-22;
        background-color: rgba(38, 174, 97, 0.1);
        color: $theme-color-2;
        &:hover {
          background-color: $theme-color-2;
          color: $color-light;
        }
      }
      &:after {
        position: absolute;
        content: "";
        bottom: -6px;
        right: 30px;
        @include transform(rotate(45deg));
        width: 12px;
        height: 12px;
        background-color: $color-light;
        border-right: 1px solid rgba(128, 137, 150, 0.2);
        border-bottom: 1px solid rgba(128, 137, 150, 0.2);
        @media #{$small_mobile_five} {
          left: auto;
          right: 25px;
          @include transform(rotate(45deg));
        }
      }
    }
    .dropdown-menu.show {
      left: -130px !important;
      top: -127px !important;
      @include transform(translate3d(0, 0, 0) !important);
      @media #{$small_mobile_five} {
        left: -135px !important;
      }
    }
    .message-control {
      height: 60px;
      background-color: rgba(128, 137, 150, 0.1);
      @include border-radius(100px);
      border: none;
      padding-left: 65px;
    }
    .form-group {
      margin-right: 10px;
      @media #{$small_mobile_two} {
       margin-right: 5px;
      }
      .submit-btn {
        background-color: $theme-color-2;
        color: $color-light;
        @include border-radius(50%);
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: $display-21;
        outline: none;
        right: 12px;
        padding: 0;
        &:hover {
          background-color: $theme-color-3;
        }
      }
    }
  }
}

.manage-job-header {
  background-color: $color-light-grey;
  @include border-radius(4px);
  padding: 10px 20px 0 20px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  @media #{$small_mobile_four} {
    display: block;
  }
}

.manage-job-count {
  padding-bottom: 10px;
  .icon-element {
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: $default-font-2;
    display: inline-block;
    margin-right: 5px;
  }
}
