.icon-element {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: $theme-color-2;
  @include border-radius(50%);
  position: relative;
  font-size: $display-18;
  @include transition(0.3s);
  color: $color-light;
}
.icon-element-2 {
  display: inline-block;
  font-size: $display-10;
  color: $theme-color;
  width: 80px;
  height: 80px;
  line-height: 77px;
  text-align: center;
  border: 2px solid $theme-color;
  @include border-radius(50%);
  @include transition(0.3s);
  position: relative;
  @media #{$small_mobile_four} {
    width: 70px;
    height: 70px;
    line-height: 67px;
  }
  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    background-color: $color-light;
    @include border-radius(50%);
  }
  &:hover {
    background-color: $theme-color-2;
    color: $color-light;
    border-color: $theme-color-2;
  }
}