.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}
.padding-50 {
  padding: 50px;
}
.padding-45 {
  padding: 45px;
}
.padding-40 {
  padding: 40px;
}
.padding-35 {
  padding: 35px;
}
.padding-30 {
  padding: 30px;
}
.padding-25 {
  padding: 25px;
}
.padding-20 {
  padding: 20px;
}
.padding-15 {
  padding: 15px;
}
.padding-10 {
  padding: 10px;
}

.padding-top-200px {
  padding-top: 200px;
}
.padding-top-190px {
  padding-top: 190px;
}
.padding-top-180px {
  padding-top: 180px;
}
.padding-top-170px {
  padding-top: 170px;
}
.padding-top-160px {
  padding-top: 160px;
}
.padding-top-150px {
  padding-top: 150px;
}
.padding-top-140px {
  padding-top: 140px;
}
.padding-top-130px {
  padding-top: 130px;
}
.padding-top-120px {
  padding-top: 120px;
}
.padding-top-110px {
  padding-top: 110px;
}
.padding-top-100px {
  padding-top: 100px;
}
.padding-top-95px {
  padding-top: 95px;
}
.padding-top-90px {
  padding-top: 90px;
}
.padding-top-85px {
  padding-top: 85px;
}
.padding-top-80px {
  padding-top: 80px;
}
.padding-top-75px {
  padding-top: 75px;
}
.padding-top-70px {
  padding-top: 70px;
}
.padding-top-60px {
  padding-top: 60px;
}
.padding-top-50px {
  padding-top: 50px;
}
.padding-top-50px {
  padding-top: 50px;
}
.padding-top-45px {
  padding-top: 45px;
}
.padding-top-40px {
  padding-top: 40px;
}
.padding-top-35px {
  padding-top: 35px;
}
.padding-top-30px {
  padding-top: 30px;
}
.padding-top-20px {
  padding-top: 20px;
}
.padding-top-10px {
  padding-top: 10px;
}


.padding-bottom-200px {
  padding-bottom: 200px;
}
.padding-bottom-190px {
  padding-bottom: 190px;
}
.padding-bottom-180px {
  padding-bottom: 180px;
}
.padding-bottom-170px {
  padding-bottom: 170px;
}
.padding-bottom-160px {
  padding-bottom: 160px;
}
.padding-bottom-150px {
   padding-bottom: 150px;
}
.padding-bottom-140px {
    padding-bottom: 140px;
 }
.padding-bottom-130px {
  padding-bottom: 130px;
}
.padding-bottom-120px {
  padding-bottom: 120px;
}
.padding-bottom-110px {
  padding-bottom: 110px;
}
.padding-bottom-100px {
  padding-bottom: 100px;
}
.padding-bottom-95px {
  padding-bottom: 95px;
}
.padding-bottom-90px {
  padding-bottom: 90px;
}
.padding-bottom-85px {
  padding-bottom: 85px;
}
.padding-bottom-80px {
  padding-bottom: 80px;
}
.padding-bottom-75px {
  padding-bottom: 75px;
}
.padding-bottom-70px {
  padding-bottom: 70px;
}
.padding-bottom-60px {
  padding-bottom: 60px;
}
.padding-bottom-50px {
  padding-bottom: 50px;
}
.padding-bottom-45px {
  padding-bottom: 45px;
}
.padding-bottom-40px {
  padding-bottom: 40px;
}
.padding-bottom-35px {
  padding-bottom: 35px;
}
.padding-bottom-30px {
  padding-bottom: 30px;
}
.padding-bottom-20px {
  padding-bottom: 20px;
}
.padding-bottom-10px {
  padding-bottom: 10px;
}

.padding-right-100px {
  padding-right: 100px;
}
.padding-right-95px {
  padding-right: 95px;
}
.padding-right-90px {
  padding-right: 90px;
}
.padding-right-85px {
  padding-right: 85px;
}
.padding-right-80px {
  padding-right: 80px;
}
.padding-right-70px {
  padding-right: 70px;
}
.padding-right-60px {
  padding-right: 60px;
}
.padding-right-55px {
  padding-right: 55px;
}
.padding-right-50px {
  padding-right: 50px;
}
.padding-right-45px {
  padding-right: 45px;
}
.padding-right-40px {
  padding-right: 40px;
}
.padding-right-30px {
  padding-right: 30px;
}

.padding-left-100px {
  padding-left: 100px;
}
.padding-left-95px {
  padding-left: 95px;
}
.padding-left-90px {
  padding-left: 90px;
}
.padding-left-85px {
  padding-left: 85px;
}
.padding-left-80px {
  padding-left: 80px;
}
.padding-left-70px {
  padding-left: 70px;
}
.padding-left-60px {
  padding-left: 60px;
}
.padding-left-55px {
  padding-left: 55px;
}
.padding-left-50px {
  padding-left: 50px;
}
.padding-left-45px {
  padding-left: 45px;
}
.padding-left-40px {
  padding-left: 40px;
}
.padding-left-30px {
  padding-left: 30px;
}

/*===== margin ====*/
.margin-top-200px {
   margin-top: 200px;
 }
.margin-top-190px {
   margin-top: 190px;
 }
.margin-top-180px {
   margin-top: 180px;
 }
.margin-top-160px {
   margin-top: 160px;
 }
.margin-top-150px {
   margin-top: 150px;
 }
.margin-top-140px {
   margin-top: 140px;
 }
.margin-top-130px {
   margin-top: 130px;
 }
.margin-top-120px {
   margin-top: 120px;
 }
.margin-top-110px {
  margin-top: 110px;
}
.margin-top-100px {
  margin-top: 100px;
}
.margin-top-95px {
  margin-top: 95px;
}
.margin-top-90px {
  margin-top: 90px;
}
.margin-top-85px {
  margin-top: 85px;
}
.margin-top-80px {
  margin-top: 80px;
}
.margin-top-70px {
  margin-top: 70px;
}
.margin-top-60px {
  margin-top: 60px;
}
.margin-top-55px {
  margin-top: 55px;
}
.margin-top-50px {
  margin-top: 50px;
}
.margin-top-45px {
  margin-top: 45px;
}
.margin-top-40px {
  margin-top: 40px;
}
.margin-top-35px {
  margin-top: 35px;
}
.margin-top-30px {
  margin-top: 30px;
}
.margin-top-20px {
  margin-top: 20px;
}
.margin-top-10px {
  margin-top: 10px;
}
.margin-top-5px {
  margin-top: 5px;
}

.margin-bottom-200px {
  margin-bottom: 200px;
}
.margin-bottom-190px {
  margin-bottom: 190px;
}
.margin-bottom-180px {
  margin-bottom: 180px;
}
.margin-bottom-170px {
  margin-bottom: 170px;
}
.margin-bottom-160px {
  margin-bottom: 160px;
}
.margin-bottom-150px {
  margin-bottom: 150px;
}
.margin-bottom-140px {
  margin-bottom: 140px;
}
.margin-bottom-130px {
  margin-bottom: 130px;
}
.margin-bottom-120px {
  margin-bottom: 120px;
}
.margin-bottom-110px {
  margin-bottom: 110px;
}
.margin-bottom-100px {
  margin-bottom: 100px;
}
.margin-bottom-95px {
  margin-bottom: 95px;
}
.margin-bottom-90px {
  margin-bottom: 90px;
}
.margin-bottom-85px {
  margin-bottom: 85px;
}
.margin-bottom-80px {
  margin-bottom: 80px;
}
.margin-bottom-70px {
  margin-bottom: 70px;
}
.margin-bottom-60px {
  margin-bottom: 60px;
}
.margin-bottom-50px {
  margin-bottom: 50px;
}
.margin-bottom-55px {
  margin-bottom: 55px;
}
.margin-bottom-45px {
  margin-bottom: 45px;
}
.margin-bottom-40px {
  margin-bottom: 40px;
}
.margin-bottom-35px {
  margin-bottom: 35px;
}
.margin-bottom-30px {
  margin-bottom: 30px;
}
.margin-bottom-20px {
  margin-bottom: 20px;
}
.margin-bottom-10px {
  margin-bottom: 10px;
}
.margin-bottom-5px {
  margin-bottom: 5px;
}

 .radius-round {
   @include border-radius(4px !important);
 }
.radius-rounded {
   @include border-radius(30px !important);
 }
.radius--rounded {
   @include border-radius(50% !important);
 }
.radius-none {
   @include border-radius(0 !important);
 }

.before-none:before {
  display: none !important;
}
.after-none:after {
  display: none !important;
}
.after-none:after,
.before-none:before {
  display: none !important;
}
.section-bg {
  background-color: $color-light-grey !important;
}
.section-bg-2 {
  background-color: $theme-color !important;
}

.section-block,
.section-block-2 {
  height: 1px;
}
.section-block {
  background-color: $color-light-grey;
}
.section-block-2 {
  background-color: $color;
}
.color-text {
  color: $theme-color-2 !important;
}
.color-text-2 {
  color: $theme-color !important;
}
.color-text-3 {
  color: $theme-color-4 !important;
}
.color-white-rgba {
  color: rgba(255, 255, 255, 0.6) !important;
}

.bg-img-transparent {
  background-image: none !important;
}
.width-vh {
  width: 100vh;
}
.height-vh {
  height: 100vh;
}
/*==== badge-closed ====*/
.badge-closed,
.section-bg-3 {
  background-color: $theme-color-2 !important;
}

.bg-1 {
    background-color: $color-3 !important;
}
.bg-2 {
    background-color: $color-4 !important;
}
.bg-3 {
    background-color: $color-5 !important;
}
.bg-4 {
    background-color: $color-6 !important;
}
.bg-5 {
  background-color: $alter-color-4 !important;
}
.bg-6 {
  background-color: $alter-color-2 !important;
}
.bg-7 {
  background-color: $alter-color-3 !important;
}

.ring-bg-1 {
  border-color: $color-3 !important;
}
.ring-bg-2 {
  border-color: $color-4 !important;
}
.ring-bg-3 {
  border-color: $color-5 !important;
}
.ring-bg-4 {
  border-color: $color-6 !important;
}
.ring-bg-5 {
  border-color: $alter-color-4 !important;
}
.overflow-inherit {
  overflow: inherit !important;
}
/*=== p-relative p-absolute ====*/
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}

/*========= preloader ==========*/
.loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: $color-light;
  top: 0;
  left: 0;
  z-index: 99999;
  .loader-circle {
    position: absolute;
    top: 50%;
    left: 50%;
   @include transform(translate(-50%, -50%));
  }
}

.loader {
  width: 40px;
  height: 40px;
  position: relative;
  animation: loader 2.5s infinite linear both;
  .loader-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    animation: loader-dot 2.0s infinite ease-in-out both;
    &:nth-child(1) {
      animation-delay: -1.1s;
      &:before {
        animation-delay: -1.1s;
      }
    }
    &:nth-child(2) {
      animation-delay: -1.0s;
      &:before {
        animation-delay: -1.0s;
      }
    }
    &:nth-child(3) {
      animation-delay: -0.9s;
      &:before {
        animation-delay: -0.9s;
      }
    }
    &:nth-child(4) {
      animation-delay: -0.8s;
      &:before {
        animation-delay: -0.8s;
      }
    }
    &:nth-child(5) {
      animation-delay: -0.7s;
      &:before {
        animation-delay: -0.7s;
      }
    }
    &:nth-child(6) {
      animation-delay: -0.6s;
      &:before {
        animation-delay: -0.6s;
      }
    }
    &:before {
      content: '';
      display: block;
      width: 25%;
      height: 25%;
      background-color: $theme-color;
      border-radius: 100%;
      animation: loader-dot-before 2.0s infinite ease-in-out both;
    }
  }
}

.ring-icon {
  border: 3px solid $theme-color;
  width: 15px;
  height: 15px;
  display: block;
  left: 24px;
  top: 24px;
  background-color: $color-light;
  @include border-radius(50%);
  z-index: 1;
  position: absolute;
}

/*====================================================
    tooltips
 ====================================================*/
.new-page-badge {
  display: inline-block;
  padding: 0 6px;
  background-color: $theme-color-5;
  color: $color-light;
  font-size: $default-font-6;
  @include border-radius(3px);
  line-height: 22px;
  float: right;
  font-weight: $font-weight-semi-bold;
}

.or-box {
  span {
    color: $theme-color;
    font-size: $display-9;
    font-weight: $font-weight-semi-bold;
    width: 60px;
    height: 60px;
    line-height: 60px;
    background-color: rgba(255, 107, 107, 0.1);
    display: inline-block;
    @include border-radius(50%);
  }
}
/*====================================================
    section-heading
 ====================================================*/
.section-heading {
  .sec__title {
    font-size: $primary-font;
    color: $theme-color;
    font-weight: $font-weight-bold;
    margin-bottom: 15px;
    position: relative;
    line-height: 50px;
    @media #{$small_mobile_two} {
      font-size: $display-17;
      line-height: 35px;
    }
    br {
       @media #{$tab_device} {
        display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
       @media #{$small_mobile} {
        display: none;
      }
    }
  }
  .sec__desc {
    font-size: $display-25;
    color: $theme-color-4;
    line-height: 30px;
    font-weight: $font-weight-regular;
    br {
      @media #{$tab_device} {
        display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
      @media #{$small_mobile} {
        display: none;
      }
    }
  }
}
.widget-title {
  font-size: $display-24;
  color: $theme-color;
  font-weight: $font-weight-bold;
  padding-bottom: 15px;
  position: relative;
}
.verified-badge {
  width: 20px;
  height: 20px;
  line-height: 21px;
  text-align: center;
  background-color: $theme-color-2;
  @include border-radius(50%);
  display: inline-block;
  font-size: $default-font-3;
}
/*====================================================
    custom-checkbox
 ====================================================*/
.custom-checkbox {
  display: block;
  margin-bottom: 12px;
  @media #{$small_mobile_three} {
    margin-right: 0;
    display: block;
  }
  label {
    color: $theme-color-4;
    font-size: $default-font-2;
    display: block;
    margin-bottom: 0;
    cursor: pointer;
    span {
      float: right;
      background-color: rgba(128, 137, 150, 0.1);
      display: block;
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      @include border-radius(30px);
      font-size: $default-font-4;
    }
  }
  input[type=checkbox]:not(:checked),
  input[type=checkbox]:checked {
    position: absolute;
    left: -9999px;
  }
  input[type=checkbox]:not(:checked) + label,
  input[type=checkbox]:checked + label {
    position: relative;
    padding-left: 26px;
  }
  input[type=checkbox]:not(:checked) + label:before,
  input[type=checkbox]:checked + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 18px;
    height: 18px;
    border: 1px solid $color-2;
    background-color: $color-light;
    @include border-radius(2px);
  }
  input[type=checkbox]:not(:checked) + label:after,
  input[type=checkbox]:checked + label:after {
    content: '\f00c';
    position: absolute;
    top: 0;
    left: 3px;
    font-size: $default-font-5;
    color: $color-light;
    @include transition(0.3s);
    font-family: "FontAwesome", sans-serif;
  }
  input[type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    visibility: hidden;
  }
  input[type=checkbox]:checked + label:after {
    opacity: 1;
    visibility: visible;
  }
  input[type=checkbox]:checked + label:before {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
  }
}
/*====================================================
    custom-label
 ====================================================*/
.label-container {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: $default-font;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input:checked ~ .label-mark {
    background-color: $theme-color-2;
  }
  input:checked ~ .label-mark:after {
    @include transform(scale(1));
  }
}
.label-mark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgba(128, 137, 150, 0.2);
  @include border-radius(50%);
  @include transition(0.3s);
  &:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    @include transform(scale(0));
    @include border-radius(50%);
    background: $color-light;
    @include transition(0.3s);
  }
}

/*====================================================
    select2-container
 ====================================================*/
.select2.select2-container {
  width: 100% !important;
}
.select2-container {
  .select2-selection--multiple,
  .select2-selection--single {
    height: auto;
    padding: 15px 20px;
    border-color: rgba(128, 137, 150, 0.3);
    font-weight: $font-weight-medium;
    outline: none;
    text-align: left;
    font-size: $default-font-3;
    .select2-selection__rendered {
      padding-left: 0;
      padding-right: 0;
      color: $theme-color-4;
      line-height: inherit;
    }
    .select2-selection__arrow {
      right: 12px;
      top: 50%;
      height: auto;
      @include transform(translateY(-50%));
      b {
        opacity: 0.8;
      }
    }
    .select2-selection__clear {
      margin-right: 15px;
      color: $theme-color-2;
    }
  }
  .select2-selection--multiple {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  &.select2-container--default {
    .select2-results {
      .select2-results__options {
        max-height: 228px;
        font-weight: $font-weight-medium;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background-color: rgba(128, 137, 150, 0.1);
          @include border-radius(10px);
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(128, 137, 150, 0.2);
          @include border-radius(10px);
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(128, 137, 150, 0.4);
        }
      }
    }
    .select2-results__option[aria-selected="true"] {
      background-color: rgba(128, 137, 150, 0.1);
    }
    .select2-results__option--highlighted[aria-selected] {
      background-color: rgba(38, 174, 97, 0.07);
      color: $theme-color-2;
    }
    .select2-dropdown {
      border-color: $color;
      .select2-search--dropdown {
        padding: 10px;
        .select2-search__field {
          border-color: rgba(128, 137, 150, 0.3);
          padding: 8px;
          @include border-radius(4px);
        }
      }
      .select2-results__option {
        padding: 10px 10px 10px 15px;
        margin-right: 10px;
        margin-left: 10px;
        @include border-radius(4px);
        font-size: $default-font-3;
      }
    }
    &.select2-container--focus {
      .select2-selection--multiple {
        border-color: rgba(128, 137, 150, 0.3);
      }
    }
    .select2-selection--multiple {
      font-size: $default-font-2;
      font-weight: $font-weight-medium;
      .select2-selection__choice {
        background-color: rgba(38, 174, 97, 0.07);
        color: $theme-color-2;
        border-color: rgba(38, 174, 97, 0.1);
        font-size: $default-font-4;
        .select2-selection__choice__remove {
          color: $theme-color-2;
        }
      }
    }
    .select2-selection--single {
      .select2-selection__placeholder {
        color: $theme-color-4;
      }
    }
  }
  .select2-search--inline {
    .select2-search__field {
      &::-moz-placeholder {
        color: $theme-color-4;
        font-weight: $font-weight-medium;
        font-size: $default-font-4;
      }
       &::placeholder {
        color: $theme-color-4;
        font-weight: $font-weight-medium;
         font-size: $default-font-4;
      }
      &:-ms-input-placeholder {
         color: $theme-color-4;
         font-weight: $font-weight-medium;
         font-size: $default-font-4;
      }
      &::-ms-input-placeholder {
         color: $theme-color-4;
         font-weight: $font-weight-medium;
         font-size: $default-font-4;
      }
    }
  }
}

.bread-action {
  .listing-info {
    li {
      margin-right: 2px;
      display: inline-block;
      &:last-child {
        margin-right: 0;
      }
      &:nth-child(1) {
        .theme-btn {
          border: 1px solid rgba(255, 255, 255, 0.3);
          background-color: transparent;
          &:hover {
            border-color: rgba(255, 255, 255, 0.6);
          }
        }
      }
      .theme-btn {
        line-height: 45px;
        padding-right: 20px;
        padding-left: 20px;
      }
    }
  }
}

.bread-details {
  .bread-img {
    width: 100px;
    height: 100px;
    @include border-radius(8px);
    margin-right: 20px;
    img {
      @include border-radius(8px);
      width: 100%;
      height: 100%;
    }
  }
  .rating-rating {
    .rating-count {
      background-color: $color-5;
      color: $color-light;
      padding: 0 6px;
      @include border-radius(4px);
      line-height: 22px;
    }
  }
}

.title-shape {
  width: 45px;
  height: 4px;
  @include border-radius(30px);
  background-color: $theme-color-2;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 13px;
    width: 5px;
    height: 5px;
    background-color: $color-light;
  }
}

/*======= select-option-wrap ========*/
.select-option-wrap {
  position: relative;
  &:after {
    position: absolute;
    content: "\f107";
    top: 19px;
    right: 16px;
    font-family: "fontAwesome";
    font-size: $display-24;
    color: $theme-color;
    pointer-events: none;
    display: inline-block;
  }
}
.select-option {
  width: 100%;
  border: 1px solid rgba(128, 137, 150, 0.3);
  padding: 16px 40px 16px 20px;
  display: block;
  color: $theme-color;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  cursor: pointer;
  outline: none;
  @include border-radius(4px);
  @include transition(0.3s);
  background-color: $color-light;
  font-weight: $font-weight-semi-bold;
  &:focus {
    border-color: rgba(128, 137, 150, 0.6);
    @include box-shadow(0 0 0 0);
  }
}

.ui-widget.ui-widget-content {
  border: none;
  background-color: $color-1;
  @include border-radius(30px);
  height: 6px;
  margin-left: 10px;
  margin-right: 10px;
  .ui-slider-range {
    background-color: $theme-color-2;
  }
  .ui-slider-handle {
    background-color: $theme-color-2;
    @include box-shadow(0 0 20px rgba(0, 0, 0, 0.05));
    border: 7px solid $color-light;
    @include border-radius(30px);
    height: 22px;
    width: 22px;
    top: -8px;
    cursor: ew-resize;
    outline: 0;
  }
}
.price-slider-amount {
  padding-top: 24px;
  font-weight: $font-weight-semi-bold;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  .filter__label {
    color: $theme-color;
    margin: 0 8px 0 0;
    font-size: $default-font-2;
  }
  .amounts {
    border: none;
    font-weight: $font-weight-semi-bold;
    color: $theme-color-4;
    background-color: transparent;
    @media #{$small_mobile_two} {
      width: 50%;
    }
  }
}

/*======= social-profile ========*/
.social-profile {
  li {
    display: inline-block;
    a {
      display: block;
      color: $theme-color;
      width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      background-color: rgba(128, 137, 150, 0.1);
      @include transition(0.3s);
      @include border-radius(50%);
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}
/*======= info-list ========*/
.info-list {
  li {
    display: block;
    color: $theme-color-4;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: $theme-color-4;
    }
    .la {
      color: $theme-color-2;
      margin-right: 2px;
      font-size: $default-font;
      width: 30px;
      height: 30px;
      background-color: rgba(38, 174, 97, 0.1);
      line-height: 30px;
      text-align: center;
      @include border-radius(50%);
      @include transition(0.3s);
    }
    &:hover {
      a {
        color: $theme-color-2;
      }
      .la {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}
/*======== img-boxes =========*/
.img-boxes {
  img {
    width: 100%;
    @include border-radius(12px);
  }
  .img-box-item.mt-4 {
    @media #{$large_mobile} {
      margin-top: 0 !important;
    }
    @media #{$small_mobile} {
      margin-top: 0 !important;
    }
  }
  .img-box-item {
    @media #{$large_mobile} {
     margin-bottom: 20px;
    }
    @media #{$small_mobile} {
     margin-bottom: 20px;
    }
  }
}
.mobile-img {
  img {
    width: 100%;
  }
}
/*======= list-items ========*/
.list-items {
  li {
    margin-bottom: 7px;
    color: $theme-color-4;
    font-size: $default-font-2;
    a {
      color: $theme-color-4;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
    span {
      font-size: $default-font-3;
      width: 25px;
      height: 25px;
      background-color: rgba(128, 137, 150, 0.1);
      @include border-radius(50%);
      text-align: center;
      line-height: 25px;
    }
  }
}
/*======== input-number-group =========*/
.input-number-group {
  p {
    color: $theme-color;
    font-size: $default-font-2;
    font-weight: $font-weight-medium;
    margin-bottom: 3px;
  }
  .input-group-button {
    display: inline-block;
    width: 28px;
    height: 28px;
    line-height: 26px;
    @include border-radius(50%);
    color: $theme-color;
    background-color: rgba(51, 63, 87, 0.1);
    font-size: $display-3;
    @include transition(0.3s);
    cursor: pointer;
    &:hover {
      color: $color-light;
      background-color: $theme-color-2;
    }
    span {
      line-height: 0;
    }
  }
  .input-number {
    border: 1px solid $color-2;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: $font-weight-medium;
    font-size: $default-font;
    @include border-radius(30px);
    color: $theme-color;
    outline: none;
    height: 35px;
    padding-left: 12px;
    @media #{$small_mobile_four} {
      padding: 0;
      width: 80px;
    }
  }
}
.tag-number {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  @include border-radius(50%);
  background-color: rgba(128,137,150,0.1);
  font-size: $default-font-3;
}

.tip {
  display: inline-block;
  cursor: help;
  @include border-radius(50%);
  color: $color-light;
  font-size: $default-font-2;
  line-height: 18px;
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: $theme-color-2;
}
.tip-verified {
  background-color: $theme-color-5;
}
/*-===============================
    PAGINATION-WRAP
===============================-*/
.page-navigation {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  width: 400px;
  @media #{$small_mobile_four} {
    width: auto;
  }
  @media #{$small_mobile_five} {
    display: block;
    text-align: center;
  }
  .page-navigation-nav {
    background-color: $color-light;
    @include border-radius(30px);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    width: 66%;
    padding: 10px 15px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
    @media #{$small_mobile_five} {
      width: 80%;
      margin: 10px auto 10px auto;
    }
    @media #{$small_mobile_two} {
      width: auto;
      margin: 10px 0 10px 0;
    }
    li {
      display: inline-block;
      font-weight: $font-weight-medium;
      &:hover .page-go-link,
      &.active .page-go-link {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
  .page-go-link,
  .page-go {
    color: $theme-color-2;
    font-size: $default-font;
    margin-left: 3px;
    @include border-radius(50%);
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    @include transition(0.3s);
    @media #{$small_mobile_five} {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .page-go {
    background-color: $color-light;
    font-size: $display-23;
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-weight: $font-weight-semi-bold;
    &:hover {
      background-color: $theme-color-2;
      color: $color-light;
    }
  }
}
/*======== generic-header =========*/
.generic-header {
  border: 1px solid rgba(128, 137, 150, 0.2);
  @include border-radius(4px);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  @media #{$large_mobile} {
    display: block;
    text-align: center;
    padding-bottom: 27px;
  }
  @media #{$small_mobile} {
    display: block;
    text-align: center;
    padding-bottom: 27px;
  }
}

.generic-header.margin-bottom-30px {
  @media #{$tab_device} {
   margin-bottom: 0;
  }
  @media #{$small_mobile} {
   margin-bottom: 0;
  }
}
.generic-nav {
  border: none;
  @media #{$large_mobile} {
    margin-top: 20px;
    -ms-flex-pack: justify;
    justify-content: center;
  }
  @media #{$small_mobile} {
    margin-top: 20px;
    -ms-flex-pack: justify;
    justify-content: center;
  }
  .nav-link {
    display: block;
    color: $theme-color-2;
    margin-right: 4px;
    font-size: $display-23;
    width: 40px;
    height: 40px;
    background-color: rgba(38, 174, 97, 0.1);
    padding: 0;
    line-height: 40px;
    text-align: center;
    margin-bottom: 0;
    border: none;
    @include border-radius(4px);
    position: relative;
    &.active {
      background-color: $theme-color-2;
      color: $color-light;
    }
    span {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      line-height: 40px;
    }
  }
}
.short-option {
  width: 200px;
  @media #{$large_mobile} {
    margin-right: auto !important;
    margin-top: 10px;
    margin-left: auto;
  }
  @media #{$small_mobile} {
    margin-right: auto !important;
    margin-top: 10px;
    margin-left: auto;
  }
  .select2-container--default {
    .select2-selection--single {
      border-color: rgba(128, 137, 150, 0.1);
      background-color: rgba(128, 137, 150, 0.1);
      padding-top: 7px;
      padding-bottom: 7px;
    }
  }
}
.showing__text {
  flex: 5;
  color: $theme-color-4;
}
.showing__text.text-left,
.showing__text.text-right {
  @media #{$large_mobile} {
    text-align: center !important;
    margin-top: 8px;
  }
  @media #{$small_mobile} {
    text-align: center !important;
    margin-top: 8px;
  }
}
.showing__text.text-left {
  @media #{$large_mobile} {
    margin-top: 0;
  }
  @media #{$small_mobile} {
    margin-top: 0;
  }
}

/*======== generic-header =========*/
.generic-header-2 {
  .generic-nav {
    @media #{$large_mobile} {
      margin-top: 10px;
    }
    @media #{$small_mobile} {
      margin-top: 10px;
    }
  }
  .short-option {
    @media #{$large_mobile} {
      margin-left: auto !important;
    }
    @media #{$small_mobile} {
      margin-left: auto !important;
    }
  }
}

/*======== dashboard-sidebar =========*/
.dashboard-sidebar {
  .dashboard-nav-container {
    @include transform(translateX(0));
    background-color: $color-light;
    width: 344px;
    padding-top: 120px;
    @media #{$laptop_m_four} {
      width: 320px;
    }
    @media #{$laptop_m_five} {
      @include transform(translateX(-100%));
      padding-top: 145px;
    }
    .humburger-menu {
      .humburger-menu-lines {
        opacity: 0;
        visibility: hidden;
        top: 95px;
        background-color: rgba(44, 44, 81, 0.1);
        @media #{$laptop_m_five} {
          opacity: 1;
          visibility: visible;
        }
        &:before,
        &:after {
          background-color: $theme-color;
        }
      }
    }
    .side-menu-wrap {
      margin-top: 0;
    }
    .side-menu-ul {
      li {
        a {
          color: $theme-color;
          i {
            margin-right: 10px;
          }
          .icon-element {
            margin-left: 0;
            display: inline-block;
            background-color: rgba(38, 174, 97, 0.1);
            width: 35px;
            height: 35px;
            line-height: 35px;
            color: $theme-color-2;
            font-size: $display-24;
          }
          .btn-toggle {
            background-color: rgba(38, 174, 97, 0.1);
            color: $theme-color-2;
          }
        }
        &:hover {
         > a {
           color: $theme-color-2;
         }
          &:after {
            background-color: $theme-color-2;
          }
          .icon-element {
            background-color: $theme-color-2;
            color: $color-light;
          }
        }
        &:after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          background-color: $color-light;
          @include border-radius(0 4px 4px 0);
          @include transition(0.3s);
        }
        &.page-active,
        &.active {
          background-color: rgba(38, 174, 97, 0.08);
          > a {
            color: $theme-color-2;
          }
          &:after {
            background-color: $theme-color-2;
          }
          .icon-element {
            background-color: $theme-color-2;
            color: $color-light;
          }
        }
        .dropdown-menu-item {
          li {
            a {
              color: $theme-color-2;
              &:hover {
                color: $theme-color-2;
              }
            }
          }
        }
      }
    }
  }
}

.typing-director {
  position: relative;
  span {
    height: 6px;
    width: 6px;
    float: left;
    margin: 0 1px;
    background-color: $theme-color-4;
    display: block;
    @include border-radius(50%);
    opacity: 0.4;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}

/*======== top =========*/
#back-to-top {
  position: fixed;
  right: -150px;
  bottom: 30px;
  z-index: 9999;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  @include border-radius(50%);
  background-color: $color-light;
  @include box-shadow(0 10px 40px rgba(82, 85, 90, 0.2));
  color: $theme-color;
  font-size: $display-23;
  @include transition(0.4s);
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  &:hover {
    background-color: $theme-color-3;
    color: $color-light;
    border-color: $theme-color-3;
  }
  &.show-back-to-top {
    right: 30px;
    opacity: 1;
    visibility: visible;
  }
}

.ads-banner {
  width: 100%;
  height: 450px;
  background-color: $color-1;
  @include border-radius(4px);
  position: relative;
  p {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    span {
      font-size: $display-10;
    }
  }
}



