/*====================================================
    map-container
 ====================================================*/
.home-map {
  .gm-fullscreen-control {
    display: none;
  }
}
.map-container {
  position: relative;
  overflow: hidden;
}
#map-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.map-height {
  height: 500px;
}
.infoBox {
  width: 310px !important;
}
.map-info-popup {
  display:block;
  position:relative;
  &:before {
    content: "";
    height: 20px;
    width: 20px;
    background-color: $color-light;
    position: absolute;
    bottom: -10px;
    left: 50%;
    @include transform(translateX(-50%) rotate(45deg));
  }
  .map-popup {
    display:block;
    width:100%;
    position:relative;
    overflow:hidden;
    @include border-radius(4px);
    background-color: $color-light;
    @include box-shadow(0 0px 9px rgba(0, 0, 0, 0.01));
    img {
      width:100%;
      height:auto;
      @include transition(0.3s);
      @include border-radius(4px 4px 14px 14px);
    }
    .close-info {
      position:absolute;
      width: 30px;
      height: 30px;
      line-height: 31px;
      top: 20px;
      text-align: center;
      right: 20px;
      color: $theme-color;
      @include border-radius(50%);
      z-index: 20;
      cursor: pointer;
      background-color: $color-light;
      font-size: $default-font;
      .la-close {
        @include transition(0.3s);
        font-weight: $font-weight-bold;
      }
      &:hover {
        .la-close {
          @include transform(rotate(90deg));
          color: $theme-color-2;
        }
      }
    }
    .map-category {
      position:absolute;
      top: 20px;
      left: 20px;
      font-weight: $font-weight-medium;
      color: $theme-color;
      z-index: 20;
      padding: 8px 15px;
      @include border-radius(30px);
      background-color: $color-light;
    }
    .map-img-box {
      &:before {
        content:'';
        position:absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: $theme-color;
        opacity:0.4;
        @include border-radius(4px 4px 12px 12px);
      }
    }
    .map-list-content {
      background-color: $color-light;
      padding:25px 20px;
      z-index:20;
      border-bottom-left-radius:10px;
      border-bottom-right-radius:10px;
      .map-ratting {
        font-size: $default-font-4;
        i {
          color: $color-5;
          margin-right: 3px;
        }
        .map-review-count {
          font-weight: $font-weight-semi-bold;
        }
      }
      .map-title h4{
        font-size: $default-font;
        font-weight: $font-weight-semi-bold;
        color: $theme-color;
        a {
          color: $theme-color;
          @include transition(0.3s);
          &:hover {
            color: $theme-color-2;
          }
        }
      }
      .map-store-call ,
      .map-location-info {
        color: $theme-color-4;
        font-size: $default-font-2;
        i {
          text-align: center;
          font-size: $default-font;
          width: 30px;
          height: 30px;
          line-height: 30px;
          @include border-radius(50%);
          background-color: rgba(255, 107, 107, 0.1);
          margin-right: 10px;
          color: $theme-color-2;
          @include transition(0.3s);
        }
        &:hover {
          i {
            background-color: $theme-color-2;
            color: $color-light;
          }
        }
      }
    }
  }
}

/*== general map style ===*/
.cluster{
  width:40px !important;
  height:40px !important;
  div {
    text-align:center;
    font-size: $default-font-2 !important;
    background: $theme-color;
    color: $color-light;
    font-weight: $font-weight-semi-bold;
    border-radius:100%;
    width:40px!important;
    height:40px!important;
    line-height:38px!important;
    @include box-shadow(0 0 0 4px rgba(0,0,0,0.5));
    border:2px solid  $theme-color;
    -webkit-transition: all 300ms linear;
    transition: all 300ms linear;
    animation: cluster-animation 1.5s infinite;
    &:hover {
      background-color: $theme-color-4;
    }
  }
}

/*====================================================
    map
 ====================================================*/
.gmaps {
  position: relative;
  #map {
    width: 100%;
    height: 500px;
  }
}