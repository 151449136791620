/*====================================================
    cta-area
 ====================================================*/
.cta-area {
  position: relative;
  .section-heading {
    .sec__title {
      margin-bottom: 10px;
    }
  }
  .btn-box {
    text-align: right;
    @media #{$tab_device} {
      text-align: center;
      margin-top: 35px;
    }
    @media #{$large_mobile} {
      text-align: center;
      margin-top: 35px;
    }
    @media #{$small_mobile} {
      text-align: center;
      margin-top: 35px;
    }
    .theme-btn {
     &:nth-child(2) {
       margin-left: 10px;
       @media #{$small_mobile_two} {
         margin-left: 0;
         margin-top: 10px;
       }
     }
    }
  }
}

.cta-area.padding-top-100px {
  @media #{$tab_device} {
    padding-top: 80px;
    padding-bottom: 104px;
  }
  @media #{$large_mobile} {
    padding-top: 80px;
    padding-bottom: 104px;
  }
  @media #{$small_mobile} {
    padding-top: 80px;
    padding-bottom: 104px;
  }
}
.cta-area.padding-top-30px {
  @media #{$large_mobile} {
    padding-bottom: 40px;
  }
  @media #{$small_mobile} {
    padding-bottom: 40px;
  }
}

.cta-area-2.padding-bottom-70px {
  @media #{$tab_device} {
    padding-bottom: 77px;
  }
}
.cta-area-2.padding-bottom-70px {
  @media #{$large_mobile} {
    padding-bottom: 77px;
  }
}
.cta-area-2.padding-bottom-70px {
  @media #{$small_mobile} {
    padding-bottom: 77px;
  }
}
.mobile-app-area {
  @media #{$laptop_m_four} {
    padding-bottom: 105px;
  }
}



.cta-text-box {
  a {
    display: block;
    padding: 20px;
    border: 1px solid rgba(128, 137, 150, 0.3);
    color: $theme-color-4;
    @include border-radius(4px);
    font-weight: $font-weight-semi-bold;
    @include transition(0.1s);
    i {
      margin-right: 10px;
      font-size: $display-14;
      width: 50px;
      height: 50px;
      line-height: 50px;
      background-color: rgba(128,137,150,0.1);
      @include border-radius(50%);
    }
    &:hover {
      border-color: rgba(128, 137, 150, 0.6);
    }
  }
}

.mobile-app-content {
  @media #{$tab_device} {
    margin-top: 20px;
  }
  @media #{$large_mobile} {
    margin-top: 20px;
  }
  @media #{$small_mobile} {
    margin-top: 20px;
  }
  .btn-box {
    @media #{$small_mobile_two} {
      text-align: center !important;
      display: block !important;
    }
    .theme-btn {
      @media #{$small_mobile_two} {
        -ms-flex-pack: center;
        justify-content: center;
      }
      &:nth-child(2) {
        margin-left: 10px;
        @media #{$small_mobile_two} {
          margin-left: 0;
          margin-top: 10px;
        }
      }
    }
  }
  .info-list {
    li {
      color: $theme-color;
      .la {
        margin-right: 20px;
        width: 80px;
        height: 80px;
        line-height: 80px;
        font-size: $display-13;
      }
    }
  }
}
/*====================================================
    cta-area 2
 ====================================================*/
.cta-area-2 {
  position: relative;
  z-index: 2;
  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.1;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='199' viewBox='0 0 100 199'%3E%3Cg fill='%23ffffff' fill-opacity='0.49'%3E%3Cpath d='M0 199V0h1v1.99L100 199h-1.12L1 4.22V199H0zM100 2h-.12l-1-2H100v2z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  }
}
/*====================================================
    subscribe-form
 ====================================================*/
.subscribe-form {
  @media #{$large_mobile} {
    display: block !important;
    text-align: center;
  }
  @media #{$small_mobile} {
    display: block !important;
    text-align: center;
  }
  .form-control,
  .select-option {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .form-group {
    .form-icon {
      top: 13px;
    }
  }
  .select-option-wrap {
    &:after {
      top: 13px;
    }
  }
  p {
    @media #{$large_mobile} {
      margin-right: 0 !important;
      margin-bottom: 8px;
    }
    @media #{$small_mobile} {
      margin-right: 0 !important;
      margin-bottom: 8px;
    }
    br {
      @media #{$tab_device} {
        display: none;
      }
      @media #{$large_mobile} {
        display: none;
      }
      @media #{$small_mobile} {
        display: none;
      }
    }
  }
}

.subscribe-form-2 {
  @media #{$tab_device} {
    display: block !important;
  }
  p {
    @media #{$tab_device} {
      font-size: 20px !important;
      line-height: 28px !important;
    }
    @media #{$large_mobile} {
      font-size: 20px !important;
      line-height: 28px !important;
    }
    @media #{$small_mobile} {
      font-size: 20px !important;
      line-height: 28px !important;
    }
  }
  .contact-form-action,
  p {
    @media #{$tab_device} {
      margin-right: 0 !important;
      margin-bottom: 20px;
    }
    @media #{$large_mobile} {
      margin-right: 0 !important;
      margin-bottom: 20px;
    }
    @media #{$small_mobile} {
      margin-right: 0 !important;
      margin-bottom: 20px;
    }
  }
}

