/*====================================================
    pricing-area
 ====================================================*/
.package-item {
  @include border-radius(4px);
  background-color: $color-light;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transition(0.3s);
  margin-bottom: 30px;
  padding: 30px 30px 35px 30px;
  border: 1px solid rgba(128, 137, 150, 0.2);
  position: relative;
  @media #{$tab_device} {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .price__title {
    font-size: $display-20;
    font-weight: $font-weight-bold;
    color: $theme-color;
    margin-bottom: 8px;
  }
  .package-info {
    background-color: rgba(44, 44, 81, 0.04);
    @include border-radius(100px);
    padding: 20px;
    text-align: center;
  }
  .dollar,
  .amount {
    color: $theme-color;
    font-weight: $font-weight-bold;
  }
  .dollar {
    font-size: $display-23;
    top: -15px;
  }
  .amount {
    font-size: $display-11;
  }
  .list-items {
    li {
      margin-bottom: 10px;
      .la {
        margin-right: 5px;
      }
    }
  }
  .theme-btn {
    background-color: $color-light;
    color: $theme-color-2;
    border: 1px solid $theme-color-2;
  }
  &:hover {
    .theme-btn {
      background-color: $theme-color-2;
      color: $color-light;
    }
  }
}


.recommended{
  font-weight: $font-weight-bold;
  @include border-radius(4px 4px 0 0);
  background-color: $theme-color-2;
  border: 1px solid $theme-color-2;
  display: block;
  text-align: center;
  color: $color-light;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 15px;
}

/*======= switch ======*/
.package-active {
  padding-top: 87px;
  margin-top: -28px;
  @media #{$tab_device} {
    margin-top: 0;
  }
  @media #{$large_mobile} {
    margin-top: 0;
  }
  @media #{$small_mobile} {
    margin-top: 0;
  }
  .package-info {
    background-color: rgba(38, 174, 97, 0.05);
    color: $theme-color-2;
  }
  .dollar,
  .amount{
    color: $theme-color-2;
  }
  .theme-btn {
    background-color: $theme-color-2;
    color: $color-light;
  }
}

.billing-switcher-action {
  .toggle,
  .toggler {
    @media #{$small_mobile_two} {
      margin: 1px;
    }
  }
  .toggle {
    @media #{$small_mobile_two} {
      width: 70px;
    }
  }
  .switch {
    @media #{$small_mobile_two} {
      right: 58.5%;
    }
  }
  .check:checked ~ .switch {
    @media #{$small_mobile_two} {
      left: 58.5%;
      right: 5px;
    }
  }
}