.tag-area.padding-top-130px {
  @media #{$small_mobile} {
    padding-top: 100px;
  }
}

.tag-list {
  li {
    display: inline-block;
    text-transform: capitalize;
    margin-bottom: 6px;
    font-weight: $font-weight-medium;
    &:last-child {
      margin-bottom: 0;
    }
    a {
     background-color: rgba(38, 174, 97, 0.06);
      color: $theme-color-2;
      @include border-radius(30px);
      @include transition(0.3s);
      padding: 6px 20px;
      display: block;
      &:after {
        display: none;
      }
    }
    &:hover {
      a {
        background-color: $theme-color-2;
        color: $color-light;
      }
    }
  }
}
.tag-items {
  @media #{$large_mobile} {
    display: block !important;
  }
  @media #{$small_mobile} {
    display: block !important;
  }
  .tag-list {
    @media #{$large_mobile} {
     margin-bottom: 15px;
    }
    @media #{$small_mobile} {
     margin-bottom: 15px;
    }
  }
}

.job-tags {
  li {
    margin-right: 5px;
    margin-bottom: 8px;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
    a {
      display: block;
      padding: 6px 16px;
      border: 1px solid rgba(128, 137, 150, 0.3);
      @include border-radius(30px);
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
    }
  }
}

.key-info-item {
  background-color: $color-light;
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.01));
  @include border-radius(4px);
  border: 1px solid rgba(128, 137, 150, 0.2);
  padding-right: 22px;
  margin-bottom: 30px;
  .list-items {
    height: 245px;
    overflow-x: hidden;
    padding: 0 22px 22px 22px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(128, 137, 150, 0.1);
      @include border-radius(10px);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(128, 137, 150, 0.2);
      @include border-radius(10px);
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(128, 137, 150, 0.4);
    }
  }
}
.job-search-list {
  li {
    a {
      border: 1px solid rgba(128, 137, 150, 0.2);
      @include border-radius(30px);
      display: inline-block;
      padding: 3px 14px;
      &:hover {
        background-color: $theme-color-2;
        color: $color-light;
        border-color: $theme-color-2;
      }
    }
  }
}