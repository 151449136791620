/*
	Template Name: Zobstar
	Author: TechyDevs
	Email: contact@techydevs.com
	Description: Zobstar - Job Board HTML Template
	Version: 1.0
*/

/*------------------------------------------------------------------
[Table of contents]

	+ General
		- common-style
	+ Header
		- Logo
		- Login
		- Sign up
		- Main Navigation
		- Post a job button
		- Offcanvas menu
	+ Content
		+ Main Files
			- index.html
			- index2.html
			- index3.html
	+ Banner Area
			- Breadcrumb
	+ Employers
	        - Employer Listing
	        - Employer Details
	        - Dashboard
	        - Edit Profile
	        - Post A Job
	+ Candidates
	        - Candidate Listing
	        - Candidate Details
	        - Add Resume
	+ Pages
			- About Us
			- Invoice
			- Pricing
			- How It Works
			- Checkout
			- Payment Complete
			- Faq
			- Contact
			- 404 Page
			- Terms And Condition
			- Recover Password
	+ blog
			- Blog Grid
			- Left Sidebar
			- Right Sidebar
			- Blog Detail
	+ Jobs
			- Job Listing
			- Job Grid View
			- Job Details
	+ Footer
		+ Top Footer
			- Company Details
			- General
			- Job Seekers
			- Employers
			- Helpful Resources
		+ Bottom Footer
		    - Copyright
		    - Browse by category
	+ Responsive Design Styles
		    - $laptop_l: '(max-width: 1440px)';
            - $laptop_m: '(max-width: 1366px)';
            - $laptop_m_two: '(max-width: 1280px)';
            - $laptop_m_three: '(max-width: 1200px)';
            - $laptop_m_four: '(max-width: 1199px)';
            - $laptop_m_five: '(max-width: 1024px)';
            - $laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
            - $medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
            - $ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
            - $tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
            - $tab_device_two: '(max-width: 768px)';
            - $large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
            - $large_mobile_two: ' (max-width: 600px)';
            - $large_mobile_three: ' (max-width: 480px)';
            - $small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
            - $small_mobile_four: '(max-width: 425px)';
            - $small_mobile_three: '(max-width: 400px)';
            - $small_mobile_five: '(max-width: 375px)';
            - $small_mobile_two: '(max-width: 320px)';
-------------------------------------------------------------------*/
// variable scss
@import "partials/variables";

// mixin scss
@import "partials/mixins";
@import "partials/keyframes";
// common scss
@import "partials/common";
// button scss
@import "partials/button";
// chart scss
@import "partials/chart";
// general scss
@import "partials/general";
// contact scss
@import "partials/contact";
// blockquote scss
@import "partials/blockquote";
// breadcrumb scss
@import "partials/breadcrumb";
// progress-bar scss
@import "partials/progress-bar";
// timeline scss
@import "partials/timeline";
// menu scss
@import "partials/menu";
// modal scss
@import "partials/modal";
// pricing scss
@import "partials/pricing";
// about scss
@import "partials/about";
// faq scss
@import "partials/faq";
// hero scss
@import "partials/hero";
// team-member scss
@import "partials/team-member";
// how-it-work scss
@import "partials/how-it-work";
// cards scss
@import "partials/cards";
// blog scss
@import "partials/blog";
// counter scss
@import "partials/counter";
// category scss
@import "partials/category";
// call-to-action scss
@import "partials/call-to-action";
// dashboard scss
@import "partials/dashboard";
// clientlogo scss
@import "partials/clientlogo";
// comment scss
@import "partials/comment";
// icon-box scss
@import "partials/icon-box";
// rating scss
@import "partials/rating";
// tab scss
@import "partials/tab";
// icons scss
@import "partials/icons";
// particles scss
@import "partials/particles";
// filer-uploader scss
@import "partials/filer-uploader";
// testimonial scss
@import "partials/testimonial";
// author scss
@import "partials/author";
// google-map scss
@import "partials/google-map";
// tags scss
@import "partials/tags";
// sidebar scss
@import "partials/sidebar";
// employer scss
@import "partials/employer";
// candidate scss
@import "partials/candidate";

// footer scss
@import "partials/footer";
