$theme-color: #021639;
$theme-color-2: #FFC530;
$theme-color-3: #e49500;
$theme-color-4: #797f87;
$theme-color-5: #e49500;
$theme-color-6: #888888;
$theme-font:  'Noto Sans JP', sans-serif;

$primary-font: 36px;
$default-font: 16px;
$default-font-2: 15px;
$default-font-3: 14px;
$default-font-4: 13px;
$default-font-5: 12px;
$default-font-6: 11px;
$color-light: #fff;
$color-light-grey: #F5F7FC;
$color-dark: #000;
$display: 100px;
$display-2: 90px;
$display-3: 85px;
$display-4: 80px;
$display-5: 75px;
$display-6: 70px;
$display-7: 65px;
$display-8: 60px;
$display-9: 55px;
$display-10: 50px;
$display-11: 45px;
$display-12: 40px;
$display-13: 35px;
$display-14: 34px;
$display-15: 33px;
$display-16: 32px;
$display-17: 30px;
$display-18: 28px;
$display-19: 26px;
$display-20: 24px;
$display-21: 22px;
$display-22: 21px;
$display-23: 20px;
$display-24: 18px;
$display-25: 17px;

$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
$text-height: 27px;
$text-height-2: 30px;
$text-height-3: 36px;
$color: #e4e4e4;
$color-1: #eee;
$color-2: #ddd;
$color-3: #8C43FF;
$color-4: #28d5a7;
$color-5: #408EFB;
$color-6: #CC08E9;
$color-7: #FF8C2A;
$color-8: #bfff02;
$color-9: #307CF3;
$alter-color: #3b5998;
$alter-color-2: #1da1f2;
$alter-color-3: #e1306c;
$alter-color-4: #2877B5;
$alter-color-5: #f00125;

$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device_two: '(max-width: 768px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_2: 'only screen and (min-width: 481px) and (max-width: 767px)';
$large_mobile_3: 'only screen and (min-width: 320px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 480px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_four: '(max-width: 425px)';
$small_mobile_three: '(max-width: 400px)';
$small_mobile_five: '(max-width: 375px)';
$small_mobile_two: '(max-width: 320px)';


$device-481: '(max-width: 481px)';
$device-575: '(max-width: 575px)';
$device-767: '(max-width: 767px)';
$extra-device: '(max-width: 540px)';
$extra-device2: '(max-width: 800px)';
$extra-device3: '(max-width: 599px)';
$extra-device4: '(min-width: 992px)';
$extra-device4: '(min-width: 992px)';
