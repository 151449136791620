/*====================================================
    single-employer
 ====================================================*/
.video-employer {
  .video__box {
    position: relative;
    @include border-radius(4px);
    img {
      width: 100%;
      @include border-radius(4px);
    }
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(44, 44, 81, 0.6);
      @include border-radius(4px);
    }
  }
  .video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    @include transform(translate(-50%, -50%));
    z-index: 1;
  }
}
.contact-listing {
  .info-list {
    li {
      @media #{$small_mobile_two} {
        display: block;
      }
      span {
        color: $theme-color;
        width: 140px;
        display: inline-block;
        @media #{$small_mobile_two} {
          width: 100%;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.social-contact {
  a {
    line-height: 45px;
    @media #{$laptop_m_four} {
      margin-bottom: 5px;
    }
    &:hover {
      @include transform(scale(1.04));
    }
    &.facebook-link {
      background-color: $alter-color;
    }
    &.twitter-link {
      background-color: $alter-color-2;
    }
    &.instagram-link {
      background-color: $alter-color-3;
    }
    &.linkedin-link {
      background-color: $alter-color-4;
    }
    &.youtube-link {
      background-color: $alter-color-5;
    }
  }
}

.single-listing-wrap {
  @media #{$tab_device} {
    margin-bottom: 60px;
  }
  @media #{$large_mobile} {
    margin-bottom: 60px;
  }
  @media #{$small_mobile} {
    margin-bottom: 60px;
  }
}

.feedback-card-box {
  .icon-box {
    @media #{$tab_device} {
     text-align: center;
    }
    @media #{$large_mobile} {
     text-align: center;
    }
    @media #{$small_mobile} {
     text-align: center;
    }
    .info__title {
      @media #{$tab_device} {
       -ms-flex-pack: justify;
        justify-content: center;
      }
      @media #{$large_mobile} {
       -ms-flex-pack: justify;
        justify-content: center;
      }
      @media #{$small_mobile} {
       -ms-flex-pack: justify;
        justify-content: center;
      }
    }
  }
}