* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
a:hover,
a:focus{
  text-decoration: none;
}
input:focus {
  outline: none;
}
h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}
body {
  font-size: $default-font;
  font-weight: $font-weight-regular;
  line-height: 28px;
  font-family: $theme-font;
  color: $theme-color-4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*---------------- fonts ----------------*/
.font-size-50 {
  font-size: 50px !important;
}
.font-size-45 {
  font-size: 45px !important;
}
.font-size-40 {
  font-size: 40px !important;
}
.font-size-35 {
  font-size: 35px !important;
}
.font-size-30 {
  font-size: 30px !important;
}
.font-size-28 {
  font-size: 28px !important;
}
.font-size-27 {
  font-size: 27px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
.font-size-25 {
  font-size: 25px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-23 {
  font-size: 23px !important;
}
.font-size-22 {
  font-size: 22px !important;
}
.font-size-21 {
  font-size: 21px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-19 {
  font-size: 19px !important;
}
.font-size-18 {
  font-size: 18px !important;
}
.font-size-17 {
  font-size: 17px !important;
}
.font-size-16 {
  font-size: 16px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-13 {
  font-size: 13px !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-11 {
  font-size: 11px !important;
}

/*---------------- font-weight ----------------*/
.font-weight-thin {
  font-weight: $font-weight-thin !important;
}
.font-weight-light {
  font-weight: $font-weight-light !important;
}
.font-weight-regular {
  font-weight: $font-weight-regular !important;
}
.font-weight-medium {
  font-weight: $font-weight-medium !important;
}
.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}
.font-weight-bold {
  font-weight: $font-weight-bold !important;
}

/*---------------- line-height ----------------*/
.line-height-65 {
  line-height: 65px !important;
}
.line-height-60 {
  line-height: 60px !important;
}
.line-height-58 {
  line-height: 58px !important;
}
.line-height-57 {
  line-height: 57px !important;
}
.line-height-56 {
  line-height: 56px !important;
}
.line-height-55 {
  line-height: 55px !important;
}
.line-height-52 {
  line-height: 52px !important;
}
.line-height-50 {
  line-height: 50px !important;
}
.line-height-48 {
  line-height: 48px !important;
}
.line-height-46 {
  line-height: 46px !important;
}
.line-height-45 {
  line-height: 45px !important;
}
.line-height-40 {
  line-height: 40px !important;
}
.line-height-35 {
  line-height: 35px !important;
}
.line-height-30 {
  line-height: 30px !important;
}
.line-height-28 {
  line-height: 28px !important;
}
.line-height-27 {
  line-height: 27px !important;
}
.line-height-26 {
  line-height: 26px !important;
}
.line-height-25 {
  line-height: 25px !important;
}
.line-height-24 {
  line-height: 24px !important;
}
.line-height-22 {
  line-height: 22px !important;
}
.line-height-20 {
  line-height: 20px !important;
}
.line-height-18 {
  line-height: 18px !important;
}

/*======= switch ======*/
.toggle,
.toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  @include transition(0.3s);
}
.toggler {
  color: $theme-color-4;
  font-weight: $font-weight-bold;
  .badge {
    background-color: rgba(38, 174, 97, 0.1);
    color: $theme-color-2;
    font-size: $default-font-2;
    padding: 6px 10px;
    font-weight: $font-weight-medium;
  }
}
.badge-before {
  color: $color-5 !important;
  background-color: rgba(249, 184, 81, 0.1) !important;
  text-decoration: line-through !important;
}
.toggler-is-active {
  color: $theme-color-2;
}
.toggle {
  position: relative;
  width: 80px;
  height: 35px;
  @include border-radius(30px);
  background-color: rgba(38, 174, 97, 0.1);
  overflow: hidden;
}
.toggle-bg-active {
  background-color: $theme-color-2;
}
.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .switch {
  right: 5px;
  left: 62.5%;
  @include transition(0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86));
  transition-property: left, right;
  transition-delay: .08s, 0s;
  background-color: $color-light;
}

.switch {
  position: absolute;
  left: 5px;
  top: 5px;
  bottom: 5px;
  right: 62.5%;
  background-color: $theme-color-2;
  @include border-radius(30px);
  z-index: 1;
  @include transition(0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86));
  transition-property: left, right;
  transition-delay: 0s, .08s;
}
.package-hide{
  display: none;
}

/*---------------- container ----------------*/
.container {
  max-width: 1200px;
}

@media (min-width:576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width:768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width:992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width:1200px) {
  .container {
    max-width: 1200px;
  }
}

.column-sm-center {
  @media #{$tab_device} {
   text-align: center !important;
  }
  @media #{$large_mobile} {
   text-align: center !important;
  }
  @media #{$small_mobile} {
   text-align: center !important;
  }
}
.column-sm-left {
  @media #{$tab_device} {
   text-align: left !important;
  }
  @media #{$large_mobile} {
   text-align: left !important;
  }
  @media #{$small_mobile} {
   text-align: left !important;
  }
}
.column-sm-right {
  @media #{$tab_device} {
   text-align: right !important;
  }
  @media #{$large_mobile} {
   text-align: right !important;
  }
  @media #{$small_mobile} {
   text-align: right !important;
  }
}
.column-lg-3 {
  @media #{$laptop_m_four} {
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    max-width: 33%;
  }
}
.column-lg-6 {
  @media #{$laptop_m_four} {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.column-lg-full {
  @media #{$laptop_m_four} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.column-lg-half {
  @media #{$laptop_m_four} {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.column-td-6 {
  @media #{$tab_device} {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.column-md-3 {
  @media #{$large_mobile} {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}
.column-md-6 {
  @media #{$large_mobile_3} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.column-sd-full {
  @media #{$small_mobile} {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

