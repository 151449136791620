/*====================================================
    header
 ====================================================*/
.menu-full-width {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  font-weight: $font-weight-medium;
}
.logo {
  padding-top: 25px;
  padding-bottom: 25px;
  z-index: 2;
  a {
    display: block;
  }
  @media #{$laptop_m_four} {
    padding: 0;
  }
}

/*======== main-menu-content =========*/
.main-menu-content {
  padding-top: 10px;
  margin-left: auto;
  text-align: right;
  padding-right: 18px;
  @media #{$laptop_m_four} {
    display: none;
  }
  nav {
    ul {
      li {
        display: inline-block;
        text-transform: capitalize;
        padding-top: 17px;
        padding-right: 15px;
        position: relative;
        font-size: $default-font-2;
        a {
          color: $theme-color;
          display: block;
          @include transition(0.3s);
          position: relative;
          z-index: 1;
          padding: 0 0 28px 0;
          .la-angle-down {
            font-size: $default-font-4;
          }
          &:hover {
            color: $theme-color-2;
          }
        }
        .dropdown-menu-item {
          text-align: left;
          position: absolute;
          top: 65px;
          left: 0;
          width: 210px;
          background-color: $color-light;
          padding-top: 12px;
          padding-bottom: 12px;
          @include border-radius(4px);
          opacity: 0;
          visibility: hidden;
          @include transform(translate(0, -10%) scale(0.9));
          z-index: 1;
          @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
          @include transition(0.4s);
          font-weight: $font-weight-regular;
          li {
            display: block;
            padding-top: 0;
            padding-right: 0;
            a {
              color: rgba(44, 44, 81, 0.7);
              display: block;
              text-transform: capitalize;
              padding: 4px 25px;
              position: relative;
              font-size: $default-font-2;
              &:after {
                position: absolute;
                content: "";
                top: 2px;
                left: 0;
                width: 3px;
                height: 85%;
                opacity: 0;
                visibility: hidden;
                background-color: $theme-color-2;
                @include border-radius(0px 4px 4px 0px);
                @include transition(0.3s);
              }
              &:hover {
                color: $theme-color-2;
                &:after {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }
        &:hover {
          .dropdown-menu-item {
            opacity: 1;
            visibility: visible;
            @include transform(translate(0, 0) scale(1));
          }
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
/*======== logo-right-content =========*/
.logo-right-content {
  position: relative;
  z-index: 1;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  @media #{$laptop_m_four} {
    margin-left: auto;
  }
  .author-access-list {
    @media #{$laptop_m_four} {
      display: none;
    }
    li {
      display: inline-block;
      padding-right: 23px;
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $theme-color;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
        }
        &.theme-btn {
          color: $color-light;
        }
      }
      .or-text {
        display: inline-block;
        padding: 0 4px;
      }
    }
  }
  .side-menu-open {
    width: 50px;
    padding: 10px;
    cursor: pointer;
    @include border-radius(5px);
    display: none;
    background-color: rgba(44, 44, 81, 0.06);
    @media #{$laptop_m_four} {
      display: block;
    }
    .menu__bar {
      height: 2px;
      background-color: $theme-color;
      display: block;
      @include transition(0.3s);
      &:nth-child(1),
      &:nth-child(2) {
        margin-bottom: 5px;
      }
      &:nth-child(2) {
        margin-right: 5px;
      }
    }
    &:hover {
      .menu__bar {
        &:nth-child(2) {
          margin-right: 0;
          margin-left: 5px;
        }
      }
    }
  }
  .user-menu-open {
    color: $theme-color;
    font-size: $display-20;
    cursor: pointer;
    @include transition(0.3s);
    display: none;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: rgba(44, 44, 81, 0.06);
    @include border-radius(4px);
    @media #{$small_mobile_four} {
      display: block;
    }
    &:hover {
      color: $theme-color;
    }
  }
}

.header-menu-wrapper {
  &.header-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    @include transition(0.5s);
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
    background-color: $color-light;
    @media #{$laptop_m_four} {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.humburger-menu-lines {
  display: inline-block;
  text-align: right;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  top: 25px;
  right: 18px;
  background-color: rgba(255, 255, 255, 0.1);
  @include border-radius(50%);
  &:before,
  &:after {
    position: absolute;
    content: '';
    height: 2px;
    width: 52%;
    top: 14px;
    left: 7px;
    opacity: 0.6;
    background-color: $color-light;
    @include transform(rotate(-43deg));
    @include transition(0.3s);
  }
  &:after {
    @include transform(rotate(43deg));
  }
  &:hover {
    &:before,
    &:after {
      @include transform(rotate(0));
    }
  }
}

.dashboard-nav-container,
.side-nav-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 320px;
  height: 100%;
  background-color: $theme-color;
  overflow-x: hidden;
  z-index: 999;
  @include transition(0.4s cubic-bezier(0.7, 0, 0.2, 1));
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transform(translateX(-100%));
  .side-menu-wrap {
    margin-top: 100px;
  }
  .side-menu-ul {
    > li {
      position: relative;
      font-weight: $font-weight-medium;
      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.1);
      }
      @include transition(0.3s);
     > a {
        font-size: $default-font-3;
        color: $color-light;
        display: block;
        padding: 14px 20px;
        @include transition(0.3s);
        position: relative;
        .btn-toggle {
          width: 33px;
          height: 33px;
          text-align: center;
          line-height: 33px;
          background-color: rgba(255, 255, 255, 0.1);
          color: $color-light;
          display: block;
          position: absolute;
          top: 50%;
          right: 15px;
          @include transform(translateY(-50%));
          font-size: $display-24;
          @include transition(0.3s);
          @include border-radius(50%);
        }
        &:hover {
          .btn-toggle {
            background-color: $theme-color-2;
            color: $color-light;
          }
        }
      }
      .dropdown-menu-item {
        display: none;
        li {
          &:after {
            display: none;
          }
          a {
            padding-left: 35px;
            padding-bottom: 9px;
            color: rgba(255, 255, 255, 0.7);
            font-size: $default-font-4;
            font-weight: $font-weight-medium;
            display: block;
            @include transition(0.3s);
            &:hover {
              color: $color-light;
            }
          }
          &:last-child {
            a {
              padding-bottom: 22px;
            }
          }
        }
      }
      &.active {
        a {
          .btn-toggle {
            background-color: $theme-color-2;
            color: $color-light;
            &:before {
              content: "\f0d8";
            }
          }
        }
      }
    }
  }
  &.active {
    @include transform(translateX(0));
  }
}

.user-nav-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  height: 100%;
  background-color: $color-light;
  overflow-x: hidden;
  z-index: 999;
  @include transition(0.4s cubic-bezier(0.7, 0, 0.2, 1));
  @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
  @include transform(translateX(100%));
  @media #{$small_mobile_five} {
    width: 320px;
  }
  .humburger-menu-lines {
    top: 10px;
    background-color: rgba(44, 44, 81, 0.1);
    &:before,
    &:after {
      background-color: $theme-color;
    }
  }
  .user-panel-content {
    margin-top: 20px;
  }
  .user-sidebar-item {
    .mess__body {
      max-height: inherit;
      overflow-x: inherit;
      overflow-y: inherit;
    }
  }
  &.active {
    @include transform(translateX(0));
  }
}

.user-panel-nav {
  .nav-tabs {
    padding: 45px 20px 0 20px;
    border-bottom-color: rgba(127, 137, 150, 0.1);
  }
  .nav-item {
    margin-right: 20px;
    font-size: $default-font-2;
    font-weight: $font-weight-medium;
  }
  .nav-link {
    color: $theme-color-4;
    border: none;
    padding: 5px 0 15px 0;
    @include border-radius(0);
    position: relative;
    &:after {
      position: absolute;
      content: "";
      bottom: 1px;
      left: 50%;
      @include transform(translateX(-50%));
      width: 40px;
      height: 3px;
      opacity: 0;
      visibility: hidden;
      background-color: $theme-color-2;
      @include border-radius(4px 4px 0 0);
      @include transition(0.3s);
    }
    &:hover {
      color: $theme-color-2;
    }
    &.active {
      background-color: transparent;
      color: $theme-color-2;
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.side-nav-button {
  padding-top: 30px;
  text-align: center;
  font-weight: $font-weight-medium;
  a {
    color: $color-light;
    @include transition(0.3s);
    &:hover {
      color: $theme-color-2;
    }
  }
  .or-text {
    margin-left: 5px;
    margin-right: 5px;
  }
  .theme-btn {
    line-height: 40px;
    margin-left: 10px;
    &:hover {
      color: $color-light;
    }
  }
}
/*======= header-area ========*/
.header-area {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1010;
  .header-menu-wrapper {
    background-color: $color-light;
    @media #{$laptop_m_four} {
      height: 75px;
      padding-top: 16px;
    }
  }
}
/*====================================================
    header-area 2
 ====================================================*/
.header-area-2 {
  .header-menu-wrapper {
   background-color: transparent;
    &.header-fixed {
      background-color: $theme-color;
    }
  }
  .main-menu-content {
    nav {
      ul {
        li {
          a {
            color: $color-light;
            &:hover {
              color: $theme-color-2;
            }
          }
        }
      }
    }
  }
  .logo-right-content {
    .author-access-list {
      li {
        a {
          color: $color-light;
          &:hover {
            color: $theme-color-2;
          }
          &.theme-btn {
            &:hover {
              color: $color-light;
            }
          }
        }
      }
    }
    .side-menu-open {
      background-color: rgba(255, 255, 255, 0.06);
      .menu__bar {
        background-color: $color-light;
      }
    }
  }
}








